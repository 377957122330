











































































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/gas-contract-view-label.json';
import GasContractBox from '@/components/energy/GasContractBox.vue';

@Component({
  components: {
    GasContractBox
  }
})
export default class GasContractView extends Vue {
  readonly label: any = label;

  @Prop({ required: true })
  readonly contract!: any;

  @Prop({ required: true })
  readonly invoice!: any;

  @Prop({ required: true })
  readonly invoices!: any;

  @Prop({ required: true })
  readonly selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  /**
   * 契約ステータス
   */
  get contractStatus() {
    const cd = this.contract.contract_status_type;
    return this.$cls.getName('DENGASU_CONTRACT_STATUS_CLS', cd);
  }

  /**
   * 対象の住所区分が設置場所住所と同じであるか判定
   */
  isSameAddress(cd: string) {
    return cd === this.$cls.GAS_CONTRACT_ADDRESS_CLS.SAME.CD;
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
